export const ANNOUNCEMENTS = [
  {
    img: "penilaian-btp-sd.jpeg",
    title: "Pengumuman Penilaian BTP Koding dan KA Tahun 2025",
    description: "Penilaian BTP Koding dan Kecerdasan Artifisial",
    url:
      "https://ik.imagekit.io/8jggdaymrs/app.buku.kemdikbud.go.id/pengumuman/Pengumuman%20Penilaian%20BTP%20Koding%20dan%20KA%20Tahun%202025.pdf?updatedAt=1741494690014",
  },
  {
    img: "penilaian-btp-sd.jpeg",
    title: "Pengumuman Penilaian BTP SMK Tahun 2024",
    description: "Penilaian Buku Teks Pelajaran SMK",
    url:
      "https://ik.imagekit.io/pusatperbukuan/Pengumuman/Pengumuman_Penilaian_BTP_SMK_Maret_2024.pdf?updatedAt=1711603417082",
  },
  {
    img: "penilaian-buku-non-teks.jpeg",
    title: "Pengumuman Penilaian BTP Kelas III, VI, IX, XII Tahun 2024",
    description: "Penilaian Buku Teks Pelajaran",
    url:
      "https://ik.imagekit.io/pusatperbukuan/Pengumuman/Pengumuman%20Penilaian%20BTP%203,6,9,12%20(2024)%20New.pdf?updatedAt=1709611901667",
  },
  {
    img: "penilaian-btp-sd.jpeg",
    title: "Pengumuman Pembukaan Penilaian Buku Nonteks 2024",
    description: "Penilaian Buku Nonteks",
    url:
      "https://ik.imagekit.io/6ix6n7mhslj/SIBI/Draft%20Pengumuman%20BNT_TM3t2oPim.pdf?updatedAt=1709293241535",
  },
  {
    img: "penilaian-buku-non-teks.jpeg",
    title: "SK Penilaian BNT Gelombang ke-2 Tahun 2023",
    description: "Pengumuman SK Penilaian Buku Non Teks",
    url:
      "https://ik.imagekit.io/6ix6n7mhslj/SIBI/Salinan%20SK%20Kabadan%20Nomor%20050_H_P_2023%20ttg%20BNT%20PAUDDASMEN_luyyrD0cf.pdf?updatedAt=1709001609597",
  },
  {
    img: "penilaian-btp-sd.jpeg",
    title: "SK Penilaian Rekomendasi Buku Nonteks Dukungan PAUD ke SD",
    description: "Pengumuman SK Penilaian Buku Nonteks",
    url:
      "https://ik.imagekit.io/pusatperbukuan/Pengumuman/SK%20Kepmen%20No.%20051.H.P.2023%20-%20BNT%20Transisi%20PAUD-SD%20Desember%202023.pdf.pdf?updatedAt=1709611607841",
  },
  {
    img: "penilaian-buku-non-teks.jpeg",
    title: "SK Penilaian Buku Non Teks Gelombang 1 Tahun 2023",
    description: "Pengumuman SK Penilaian Buku Non Teks",
    url:
      "https://ik.imagekit.io/z5w8c5jluyvp/Salinan%20Kepmen%20029_H_P_2023%20tentang%20Penetapan%20BNT%20pada%20PAUD,%20Pendidikan%20Dasar%20dan%20Pendidikan%20Menengah%20y_aw4dpTP5f.pdf",
  },
  {
    img: "penilaian-buku-rekomendasi.jpeg",
    title: "SK Buku Rekomendasi Gelombang 1 Tahun 2023 ",
    description: "Pengumuman SK Penilaian Buku Rekomendasi",
    url:
      "https://ik.imagekit.io/z5w8c5jluyvp/SK.Salinan%20No.026.A.H.P.2023%20tentang%20Penetapan%20Buku%20Nonteks%20Pendidikan%20Dasar%20yang%20Memenuhi%20Syarat%20Kelay_n3Xb5z99S.pdf",
  },
  {
    img: "penilaian-buku-non-teks.jpeg",
    title: "Pengumuman Penilaian BTP untuk Kelas II, V, VIII, dan XI      ",
    description:
      "Pendaftaran penilaian BTP 2023 dibuka kembali. Untuk bapak/ibu penerbit yang ingin berpartisipasi sillakan mengklik tombol daftar.",
    url:
      "https://static.buku.kemdikbud.go.id/content/pengumuman/Pengumuman%20Penilaian%20BTP%202,5,811%20(2023).pdf",
  },
  {
    img: "penilaian-btp-smk.png",
    title: "Penilaian BTP untuk SMK/MAK",
    description:
      "Pendaftaran Penilaian BTP Kurikulum Merdeka Untuk SMK/MAK telah Dibuka. Bapak/ibu yang sudah melakukan Pra-Registrasi harap login dan mendaftarkan buku, dengan cara menyunting buku tersebut.",
    url: "https://app.buku.kemdikbud.go.id/user/penilaian",
  },
  {
    img: "sk-btp.png",
    title: "SK - Buku Teks Pendamping Tahun 2023",
    description:
      "Hasil Penilaian Buku Teks Pendamping Tahun Untuk Kelas I, IV, VII, dan X Tahun 2023 Telah Terbit! Bapak/ibu penerbit dapat mengecek buku lolos/tidak lolos pada tautan berikut ini",
    url:
      "https://static.buku.kemdikbud.go.id/content/undang-undang/Salinan%20SK%20Nomor%20%20018.B_H_P_2023%20tentang%20Penetapan%20Buku%20Teks%20Pendampingan%20untuk%20Kelas%20I.IV.VII%20dan%20X.pdf",
  },
  {
    img: "pengajuan-isbn-internal.jpeg",
    title: "Pengajuan ISBN Internal Kemendikbudristek Tahun 2022",
    description:
      "Berikut ini tata cata / panduan pengajuan ISBN untuk Internal Kemdikbud",
    url:
      "https://docs.google.com/document/d/1Adv8FHXxzpy48bmAtW1qhtUqVHLCrESl/edit",
  },
  {
    img: "sk-bnt-gelombang-3.png",
    title: "SK - Buku Non Teks Gelombang 3 Tahun 2022",
    description:
      "Hasil Penilaian Buku Non Teks Gelombang 3 Tahun 2022 Telah Terbit! Bapak/ibu penerbit dapat mengecek buku lolos/tidak lolos pada tautan berikut ini",
    url:
      "https://static.buku.kemdikbud.go.id/content/undang-undang/Salinan%20SK%20Nomor%20017_H_P_2023%20ttg%20%20Buku%20Nonteks%20pada%20pendidikan%20dasar%20dan%20pendidikan%20menengah.pdf",
  },
  {
    img: "penilaian-buku-teks-pendamping.jpeg",
    title: "Penilaian Buku Teks Pendamping 2022",
    description:
      "Selamat datang Bapak/Ibu. Pendaftaran Penilaian Buku Teks Pelajaran Tahun 2022 terlah dibuka. Silahkan login di aplikasi, dan kunjungi tautan berikut ini untuk mendaftar.",
    url: "https://app.buku.kemdikbud.go.id/event-penilaian",
  },
  {
    img: "het-gel4.png",
    title: "Pengumuman Perhitungan Harga Eceran Tertinggi",
    description:
      "Berikut ini pengumuman Perhitungan Harga Eceran Tertinggi (HET) pada Buku Non Teks Gelombang IV Tahun 2023.",
    url:
      "https://drive.google.com/file/d/1qCJ52wbAMGqeu5H_rxfYZ3qbUlcQCWzH/view?usp=sharing",
  },
  {
    img: "banner-mobile-3.jpg",
    title: "Daftar Pencetak Buku Kurikulum Merdeka",
    description:
      "Untuk bapak/ibu Pencetak, yang sudah mengajukan diri sebagai Pencetak Buku Kurikulum Merdeka. Bisa melihat daftar nama berdasarkan abjad pada tautan berikut ini.",
    url: "https://app.buku.kemdikbud.go.id/pencetak-kurikulum-merdeka",
  },
];
